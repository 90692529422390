import _ from 'lodash';
import { OhsCusval, OhsCusvalType } from './OhsCusvalModel';
import { OhsCusValCategory } from '../../../user/configurations/OhsModuleCategory';

export const parseCusvalResults = (
  payload: any,
  cusvalsConfig: OhsCusval[]
) => {
  if (payload.cusvals) {
    payload.cusvals = setCusvalValue(payload.cusvals, cusvalsConfig);
  }
  if (payload.categoryCusvals) {
    payload.categoryCusvals = setCusvalValue(
      payload.categoryCusvals,
      cusvalsConfig
    );
  }
  if (payload.subcategoryCusvals) {
    payload.subcategoryCusvals = setCusvalValue(
      payload.subcategoryCusvals,
      cusvalsConfig
    );
  }
  return payload;
};

export const returnAllCusvals = (moduleClassification: OhsCusValCategory[]) => {
  let allCusvals: OhsCusval[] = [];
  moduleClassification.map((item) => {
    let subcategoryCus: OhsCusval[] = [];
    item?.subcategory.map((sub) => subcategoryCus.push(...sub.cusvals));
    allCusvals = [...allCusvals, ...(item?.cusvals || []), ...subcategoryCus];
  });
  return allCusvals;
};

export const setCusvalValueToForm = (cusField: any) => {
  if (cusField) {
    cusField = getFormInitialObject(cusField as OhsCusval[]);
  }
  return cusField;
};
export const setCusvalValue = (
  cusvalObj: any,
  cusvalsConfig: OhsCusval[],
  customPropValues?: any[]
) => {
  const cusvals = new Array<OhsCusval>();
  const keyList = Object.keys(cusvalObj);
  for (let index = 0; index < keyList.length; index++) {
    const cusvalId = keyList[index];
    const cusval = _.find(cusvalsConfig, { _id: cusvalId });

    const value = cusvalObj[cusvalId];

    // Handle all possible default values for form fields
    if (
      cusval &&
      value &&
      value !== undefined &&
      value !== null &&
      value !== '' &&
      value !== '#000000'
    ) {
      if (cusval.type === OhsCusvalType.Time) {
        if (value == '' || value == null) {
          cusval.value = null;
        } else if (value.split) {
          let timeParts = value.split(':');
          cusval.value = {
            hours: parseInt(timeParts[0], 10),
            minutes: parseInt(timeParts[1], 0),
          };
        }
      } else if (cusval.type === OhsCusvalType.Radio) {
        if (value) {
          cusval.value = [value];
        } else {
          cusval.value = value;
        }
      } else {
        cusval.value = value;
      }

      cusvals.push(cusval);
    } else {
      const invalidItem = _.find(customPropValues, { _id: cusvalId });
      if (invalidItem) {
        cusvals.push(invalidItem);
      }
    }
  }
  return cusvals;
};

export const returnInvalidCusvals = (
  allValues: any[],
  cusvalsConfig: OhsCusval[]
) => {
  const invalidCusvals = new Array<OhsCusval>();
  const keyList = (allValues || []).map((item) => item._id);

  for (let index = 0; index < keyList.length; index++) {
    const cusvalId = keyList[index];
    const targetCusval = _.find(cusvalsConfig, { _id: cusvalId });
    if (!targetCusval) {
      const invalidItem = _.find(allValues, { _id: cusvalId });
      invalidCusvals.push(invalidItem);
    }
  }
  return invalidCusvals;
};

const getFormInitialObject = (cusvalList: Array<OhsCusval>) => {
  let initObject: any = {};
  cusvalList.forEach((item) => {
    const value = item?.value;
    let newValue = null;
    if (value) {
      if (item.type === OhsCusvalType.Date && value.indexOf('T') > 0) {
        newValue = value.split('T')[0];
      } else if (
        item.type === OhsCusvalType.Time &&
        value.hours != null &&
        value.minutes != null
      ) {
        newValue =
          (value.hours > 9 ? value.hours : '0' + value.hours) +
          ':' +
          (value.minutes > 9 ? value.minutes : '0' + value.minutes);
      } else if (item.type === OhsCusvalType.Radio && value) {
        newValue = value[0];
      } else {
        newValue = value;
      }
    }
    if (!_.isNil(item?._id)) {
      initObject[item._id] = newValue;
    }
  });
  return initObject;
};
export function combineCusvalConfigAndValue(
  cusConfigList: OhsCusval[],
  cusValueList: OhsCusval[]
): OhsCusval[] {
  for (let index = 0; index < cusConfigList.length; index++) {
    const config = cusConfigList[index];
    const value = _.find(cusValueList, { _id: config._id });
    if (value) {
      if (
        config.type === OhsCusvalType.Date &&
        value.value &&
        value.value.indexOf('T') > 0
      ) {
        config.value = value.value.split('T')[0];
      } else if (
        config.type === OhsCusvalType.Time &&
        value.value &&
        value.value.hours != null &&
        value.value.minutes != null
      ) {
        config.value =
          (value.value.hours > 9
            ? value.value.hours
            : '0' + value.value.hours) +
          ':' +
          (value.value.minutes > 9
            ? value.value.minutes
            : '0' + value.value.minutes);
      } else if (
        (config.type === OhsCusvalType.Radio ||
          config.type === OhsCusvalType.Select) &&
        value.value
      ) {
        config.value = value.value[0];
      } else {
        config.value = value.value;
      }
    }
  }
  const unfoundList = new Array<OhsCusval>();
  for (let index = 0; index < cusValueList.length; index++) {
    const cusVale = cusValueList[index];
    const cusConfig = _.find(cusConfigList, { _id: cusVale._id });
    if (cusConfig == null) {
      cusVale.deleted = true;
      if (cusVale.type === OhsCusvalType.Checkbox) {
        cusVale.options = cusVale.value.map((item: string) => {
          return { name: item };
        });
      }
    }
  }

  return unfoundList.concat(cusConfigList);
}
function flattenNestedCusvalSelectArray(nestedArray: any) {
  return nestedArray
    ? nestedArray
        .map((level: any) => {
          if (Array.isArray(level)) {
            return level.join(' > ');
          }
          return level;
        })
        .join(', ')
    : '';
}
export const getCusvalDisplayValue = (cusval: OhsCusval) => {
  switch (cusval.type) {
    case OhsCusvalType.Color:
    case OhsCusvalType.Email:
    case OhsCusvalType.Number:
    case OhsCusvalType.Text:
    case OhsCusvalType.Textarea:
    case OhsCusvalType.Url:
    case OhsCusvalType.Telephone:
      return cusval.value;
    case OhsCusvalType.Checkbox:
    case OhsCusvalType.Select:
    case OhsCusvalType.MutipleSelect:
      return flattenNestedCusvalSelectArray(cusval.value);
    case OhsCusvalType.Radio:
      return cusval.value ? cusval.value.toString() : '';
    case OhsCusvalType.Currency:
      return cusval.value ? `${cusval.value.amount} ${cusval.value.code}` : '';
    case OhsCusvalType.Date:
      if (cusval.value) {
        if (cusval.value.indexOf('T') > -1) {
          return `${cusval.value.split('T')[0]}`;
        }
        if (typeof cusval.value == 'string') {
          return cusval.value;
        }
      }
      return '';
    case OhsCusvalType.Time:
      return cusval.value
        ? `${
            cusval.value.hours < 10
              ? '0' + cusval.value.hours
              : cusval.value.hours
          } : ${
            cusval.value.minutes < 10
              ? '0' + cusval.value.minutes
              : cusval.value.minutes
          }`
        : '';
    default:
      break;
  }
};

export const returnMatchingCusvalByConfig = (
  cusvalConfigs: OhsCusval[],
  allValues: any,
  invalidValues: OhsCusval[]
) => {
  let newCusvalObj: any = {};

  // add the invalid values
  invalidValues.map((item: OhsCusval) => {
    newCusvalObj[item._id] = item.value;
  });

  // autofill field values that are previously filled if it matches the current config
  cusvalConfigs.map((cusval: OhsCusval) => {
    const value = _.get(allValues, cusval._id) || '';
    if (value) {
      newCusvalObj[cusval._id] = value;
    }
  });

  return newCusvalObj;
};
