import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { Button } from 'semantic-ui-react';
import _ from 'lodash';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { getCurrentTimezone } from '../../common/OhsDataParse';
import OhsModal from '../../global/modal/OhsModal';
import OhsDateField from '../../global/form/fields/OhsDateField';
import OhsRadioField from '../../global/form/fields/OhsRadioField';
import {
  OhsFormLayoutType,
  OhsFormSettings,
} from '../../global/form/OhsFormModels';
import { OhsInspectionStart } from '../OhsInspectionModels';
import { startInspection } from '../OhsInspectionServices';
import { getTodayDateString } from '../../global/utils/OhsDateServices';

interface Props {
  isOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  recordData: any;
  task: any;
  onRedirect?: () => void;
  closeAllModal?: () => void;
}

const OhsInspectionStartModal = (props: Props) => {
  const { isOpen, setModalOpen, recordData, task, closeAllModal } = props;
  const recordId = recordData._id;
  const taskId = task._id;
  const navigate = useNavigate();
  const [ohsOhsInspection, setOhsInspection] = React.useState(
    new OhsInspectionStart()
  );
  const ohsFormSettings = new OhsFormSettings(
    ohsOhsInspection,
    OhsFormLayoutType.AngularModalForm
  );

  const isRecurring =
    !_.isNil(recordData.frequencyKey) && !_.isNil(recordData.frequencyValue);

  const [inspectionCommencedDate, dateCommenced]: [string, string] =
    ohsFormSettings.useFormMethods.watch([
      'inspectionCommencedDate',
      'dateCommenced',
    ]);

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleViewInspection = () => {
    navigate(`/inspection/${recordId}/details?tempType=tp`);
    navigate(0);
  };

  const formSubmit = async (formData: any) => {
    const newFormData = {
      dateCommenced: formData.dateCommenced,
      tz: getCurrentTimezone(),
      attachments: [],
    };
    setOhsInspection(newFormData);
    startInspection(recordId, taskId, newFormData, () => {
      if (props.onRedirect) {
        props.onRedirect();
      }
      if (closeAllModal) {
        closeAllModal();
      } else {
        handleClose();
      }
      navigate(`/inspection/signoff/${recordId}/signoff/${taskId}`);
      navigate(0);
    });
  };

  useEffect(() => {
    if (
      inspectionCommencedDate === 'today' &&
      dateCommenced !== moment().format('YYYY-MM-DD')
    ) {
      const newForm = {
        inspectionCommencedDate: inspectionCommencedDate,
        dateCommenced: moment().format('YYYY-MM-DD'),
      };
      ohsFormSettings.useFormMethods.reset(newForm);
    }
  }, [inspectionCommencedDate]);

  useEffect(() => {
    if (isOpen) {
      const newForm = {
        inspectionCommencedDate: 'today',
        dateCommenced: moment().format('YYYY-MM-DD'),
      };
      ohsFormSettings.useFormMethods.reset(newForm);
    }
  }, [isOpen]);

  return (
    <OhsModal
      open={isOpen}
      title={`${isRecurring ? 'Recurring ' : ''} Inspection: ${
        recordData.title
      }`}
      onClose={handleClose}
      maxWidth={'md'}
      ContentNode={
        <>
          <Box minHeight="360px">
            <Grid container>
              <Grid item xs={4}>
                <OhsRadioField
                  title="Commenced Date"
                  id="inspectionCommencedDate"
                  required={true}
                  options={[
                    { name: 'Today', value: 'today' },
                    { name: 'Other', value: 'other' },
                  ]}
                  ohsFormSettings={ohsFormSettings}
                  row
                />
              </Grid>
              <Grid item xs={8}>
                <OhsDateField
                  id="dateCommenced"
                  required={true}
                  ohsFormSettings={ohsFormSettings}
                  maxDate={{ name: 'Today', date: getTodayDateString() }}
                  disabled={inspectionCommencedDate === 'today'}
                />
              </Grid>
            </Grid>

            <Box className="field" marginTop={2}>
              <Box className="ui blue message">
                <p>
                  <b>Note:</b> Once you select “Start Inspection”, any updates
                  to the inspection template will not be available until after
                  inspection completion. If you wish to view the template,
                  select “View Inspection”
                </p>
              </Box>
            </Box>
          </Box>
        </>
      }
      actionNode={
        <Box justifyContent="space-between" width="100%" display="flex">
          <Button onClick={handleViewInspection} color="blue">
            View Inspection
          </Button>
          <Button
            onClick={ohsFormSettings.useFormMethods.handleSubmit(formSubmit)}
            color="green"
          >
            Start Inspection
          </Button>
        </Box>
      }
    />
  );
};

export default OhsInspectionStartModal;
