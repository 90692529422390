import React, { useEffect, useState } from 'react';

import _ from 'lodash';
import { Grid } from '@mui/material';
import OhsSelectField, { Props as SelectProps } from './OhsSelectField';
import OhsTextField, { Props as TextProps } from './OhsTextField';
import { OhsFormLayoutType, OhsFormSettings } from '../OhsFormModels';

interface SelectPairProps extends Omit<SelectProps, 'ohsFormSettings'> {}
interface TextPairProps extends Omit<TextProps, 'ohsFormSettings'> {}
interface Props {
  selectProps: SelectPairProps;
  textProps: TextPairProps;
  ohsFormSettings: OhsFormSettings;
  hide?: boolean;
}

function OhsSelectFieldPair(props: Props) {
  const [lastValue, setLastValue] = useState('');
  const selectValue =
    props.ohsFormSettings.useFormMethods.watch(props.selectProps.id) ?? '';
  const textValue =
    props.ohsFormSettings.useFormMethods.watch(props.textProps.id) ?? '';
  const isOther = selectValue && selectValue.toLowerCase() === 'other';

  const spacedLayout =
    props.ohsFormSettings.layout === OhsFormLayoutType.AngularModalForm ||
    props.ohsFormSettings.layout ===
      OhsFormLayoutType.AngularModalUnderlinedForm;

  useEffect(() => {
    // if text field still has value but select field is no longer "other"
    if (!_.isEmpty(textValue) && !isOther) {
      setLastValue(textValue);
      props.ohsFormSettings.useFormMethods.setValue(props.textProps.id, '');
    }
    /* if text field value is previously set, and select field value is 
    returned to "other", revert text field to last value */
    if (_.isEmpty(textValue) && !_.isEmpty(lastValue) && isOther) {
      props.ohsFormSettings.useFormMethods.setValue(
        props.textProps.id,
        lastValue
      );
      setLastValue('');
    }
  }, [selectValue]);

  if (props.hide === true) return <span />;

  return (
    <Grid container spacing={spacedLayout ? 3 : 0}>
      <Grid item xs={12}>
        {props.selectProps.disabled ? (
          <OhsTextField
            {...props.selectProps}
            type="text"
            ohsFormSettings={props.ohsFormSettings}
          />
        ) : (
          <OhsSelectField
            {...props.selectProps}
            ohsFormSettings={props.ohsFormSettings}
          />
        )}
      </Grid>
      {isOther && (
        <Grid item xs={12}>
          <OhsTextField
            {...props.textProps}
            ohsFormSettings={props.ohsFormSettings}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default OhsSelectFieldPair;
