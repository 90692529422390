import React from 'react';

import shortid from 'shortid';
import { Box, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import _ from 'lodash';
import { Divider, Icon, Label } from 'semantic-ui-react';
import OhsFormFieldLayout from '../../OhsFormFieldLayout';
import { OhsFormFieldBaseProps } from '../../OhsFormModels';
import OhsDragAndDrop from '../../../dnd/OhsDragAndDrop';
import OhsOptionWithSubMenu, { MenuItemLabel } from './OhsOptionWithSubMenus';
import { OhsOptionWithNoSubMenus } from './OhsOptionWithNoSubMenus';
import { DragAndDropSelectItem } from '../../../dnd/OhsDragAndDropDisplay';
import { returnFieldStyle, returnVariant } from '../../fields/OhsFieldLayout';

export interface SelectFieldOptionType {
  name: string;
  value: string | number | boolean;
}
interface Props extends OhsFormFieldBaseProps {
  options: Array<SelectFieldOptionType>;
  multi?: boolean;
}

export const MultiSelectContext = React.createContext<any>({ color: 'white' });
function InputEle(props: Props) {
  const disabled = props.disabled || false;
  const inputRef = props.ohsFormSettings.useFormMethods.register(props.id).ref;
  const [open, setOpen] = React.useState(false);
  const error = _.get(
    props.ohsFormSettings.useFormMethods.formState.errors,
    props.id
  );

  let result = props.ohsFormSettings.useFormMethods.watch(props.id) || [];

  const handleChange = (event: SelectChangeEvent) => {
    const value = (event.target.value as string) ?? '';

    if (value) {
      if (props.multi === true) {
        let found = false;
        result.forEach((item: string[]) => {
          if (item && item[0] && item[0] === value) {
            found = true;
          }
        });
        if (found === false) {
          props.ohsFormSettings.useFormMethods.setValue(props.id, [
            ...result,
            [value],
          ]);
        }
      } else {
        if (result.includes(value) !== true)
          props.ohsFormSettings.useFormMethods.setValue(props.id, [
            ...result,
            value,
          ]);
      }

      props.ohsFormSettings.useFormMethods.trigger(props.id);
    }
  };
  const handleRemove = (value: string | string[]) => {
    if (value) {
      props.ohsFormSettings.useFormMethods.setValue(props.id, [
        ...result.filter((item: string) => item !== value),
      ]);
      props.ohsFormSettings.useFormMethods.trigger(props.id);
    }
  };
  const handleArrange = (list: string[]) => {
    props.ohsFormSettings.useFormMethods.setValue(props.id, [...list]);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const getOptions = () => {
    let unselectedOptions: any[] = [];
    props.options.map((option: any) => {
      if (_.isNil((result || []).find((item: any) => item == option.value))) {
        unselectedOptions = [...unselectedOptions, option];
      }
    });

    return unselectedOptions;
  };
  React.useEffect(() => {
    props.ohsFormSettings.useFormMethods.register(props.id, {
      validate: {
        notEmpty: () => {
          const value =
            props.ohsFormSettings.useFormMethods.getValues(props.id) ?? [];
          if (props.required === true) {
            if (value && value.length > 0) {
              return true;
            }
          } else {
            return true;
          }

          return false;
        },
      },
    });
  }, [props.id, props.ohsFormSettings.useFormMethods, props.required, result]);

  return (
    <Box>
      <Select
        ref={inputRef}
        error={error != null}
        fullWidth
        margin={'dense'}
        required={props.required}
        displayEmpty
        onChange={handleChange}
        variant={returnVariant(props.ohsFormSettings.layout)}
        value={''}
        disabled={
          disabled || (props.multi !== true && result && result.length > 0)
        }
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        size="small"
        style={returnFieldStyle(props.ohsFormSettings.layout, {
          marginTop: '8px',
        })}
      >
        <MenuItem value="" disabled={props.required}>
          <span style={{ color: 'gray' }}>-- Please Select --</span>
        </MenuItem>
        {getOptions().map((item: any) => {
          if (item === 'SEPARATOR')
            return (
              <Divider
                key={shortid.generate()}
                component="hr"
                style={{ margin: '4px 0 4px 0' }}
              />
            );
          if (item.static)
            return (
              <MenuItemLabel
                key={shortid.generate()}
                name={item.static}
                icon={item.icon}
              />
            );
          return item.options ? (
            <OhsOptionWithSubMenu
              key={shortid.generate()}
              popupId={item.name.replace(/[^A-Z0-9]/gi, '')}
              name={item.name}
              options={item}
              setOpen={setOpen}
              ohsFormSettings={props.ohsFormSettings}
              icon={item.icom}
              id={props.id}
            />
          ) : (
            <OhsOptionWithNoSubMenus
              key={shortid.generate()}
              item={item}
              ohsFormSettings={props.ohsFormSettings}
              id={props.id}
              setOpen={setOpen}
            >
              {item.name}
            </OhsOptionWithNoSubMenus>
          );
        })}
      </Select>
      <div>
        {props.multi !== true && result.length > 0 ? (
          result.map((item: string) => (
            <Label
              key={shortid.generate()}
              as="a"
              tag
              style={{ marginTop: '5px' }}
            >
              {item}
              {!disabled && (
                <Icon
                  name="delete"
                  onClick={() => {
                    handleRemove(item);
                  }}
                />
              )}
            </Label>
          ))
        ) : (
          <OhsDragAndDrop
            list={result || []}
            setList={handleArrange}
            withHandle
            renderItem={(item: string | string[]) => {
              let label = '';
              if (Array.isArray(item)) {
                item.map((i) => (label = label === '' ? i : `${label} > ${i}`));
              } else {
                label = item;
              }
              return (
                <DragAndDropSelectItem
                  label={label}
                  onDelete={() => handleRemove(item)}
                  disabled={disabled}
                />
              );
            }}
          />
        )}
      </div>
    </Box>
  );
}

export default function OhsCusvalSelectField(props: Props) {
  return (
    <>
      <OhsFormFieldLayout {...props} inputEle={<InputEle {...props} />} />
    </>
  );
}
