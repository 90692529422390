import { Box, styled, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { getCookie } from '../../common/OhsDataParse';
import { OhsPhoneNumber } from '../../global/form/fields/OhsFormFieldModels';
import { OhsMfaLimits, OhsMFAsettings } from '../OhsUser';
import { OhsUserMFAOTPTypes } from './mfa/OhsUserSettingsModels';

export const getMultiUserConfig = function () {
  var config = localStorage.getItem('multi-users');
  if (config == null) return;
  return JSON.parse(config);
};

export const formatUserData = (userProfileData: any) => {
  const currentLang = getCookie('googtrans').replace('/en/', '');
  let updatedUserPhone: OhsPhoneNumber | undefined = undefined;

  return {
    ...userProfileData,
    username: userProfileData.name,
    language: currentLang ? currentLang : 'en',
    userphone: userProfileData.phoneCountryCode + ' - ' + userProfileData.phone,
  };
};

export const formatTime = (notifyTime: { hours: string; minutes: string }) => {
  return notifyTime
    ? moment(notifyTime.hours + `:` + notifyTime.minutes, `h:mm:ss A`).format(
        `HH:mm`
      )
    : '';
};

export const getMFAenrollmentSuccessMessages = (
  mfaInfo: OhsUserMFAOTPTypes
) => {
  const HeaderText1 = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '10px',
    textAlign: 'center',
  });
  const HeaderText2 = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '0 0 8px 0',
  });

  let content: any;
  let content2: any;

  switch (mfaInfo.method) {
    case 'email':
      content = (
        <>
          <HeaderText1>
            Your Multi-Factor Authentication is set to this email:
          </HeaderText1>
          <HeaderText2>{mfaInfo.destination}</HeaderText2>
        </>
      );
      content2 = (
        <HeaderText1>
          Authentication codes will be sent to this email for logging in.
        </HeaderText1>
      );
      break;
    case 'sms':
      content = (
        <>
          <HeaderText1>
            Your Multi-Factor Authentication is set to this phone number:
          </HeaderText1>
          <HeaderText2>{mfaInfo.destination}</HeaderText2>
        </>
      );
      content2 = (
        <HeaderText1>
          Authentication codes will be texted to this number for logging in.
        </HeaderText1>
      );
      break;
    case 'app':
      content = <HeaderText1>Your Authentication App is set.</HeaderText1>;
      content2 = (
        <HeaderText1>
          Authentication code is generated by your authentication app for
          logging in.
        </HeaderText1>
      );
      break;
    default:
      content = '';
      content2 = '';
  }
  return { content, content2 };
};

export const getMfaConfirmModalHeaders = (confirm: string) => {
  const ContentWrap = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  });
  const KeyWord = styled(Typography)({
    fontWeight: 'bold',
  });
  const HeaderText = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'baseline',
    fontSize: '16px',
    margin: '0 0 8px 0',
    textAlign: 'center',
  });
  const WarningMsg = styled('div')({
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    margin: '10px 0 15px 0',
    color: 'red',
    fontWeight: 'bold',
  });

  let content: any;
  switch (confirm) {
    case 'confirmDisable':
      content = (
        <ContentWrap>
          <HeaderText>
            To continue, please type "<KeyWord>DISABLE</KeyWord>".
          </HeaderText>
          <WarningMsg>
            This will disable Multi-Factor Authentication entirely.
          </WarningMsg>
        </ContentWrap>
      );
      break;

    case 'confirmSwitchMethod':
      content = (
        <ContentWrap sx={{ marginBottom: '20px' }}>
          <HeaderText>To continue changing Authentication Method,</HeaderText>
          <HeaderText>
            please type "<KeyWord>CHANGE</KeyWord>".
          </HeaderText>
        </ContentWrap>
      );
      break;
    default:
      content = '';
      break;
  }
  return content;
};

export const userTimeZoneList = [
  {
    value: 'Pacific/Tongatapu',
    name: "GMT+13:00 - Nuku'alofa",
  },
  {
    value: 'Pacific/Auckland',
    name: 'GMT+12:00 - Auckland, Wellington',
  },
  {
    value: 'Etc/GMT-12',
    name: 'GMT+12:00 - Fiji, Kamchatka, Marshall Is.',
  },
  {
    value: 'Asia/Magadan',
    name: 'GMT+12:00 - Magadan',
  },
  {
    value: 'Etc/GMT-11',
    name: 'GMT+11:00 - Solomon Is., New Caledonia',
  },
  {
    value: 'Asia/Vladivostok',
    name: 'GMT+11:00 - Vladivostok',
  },
  {
    value: 'Asia/Yakutsk',
    name: 'GMT+10:00 - Yakutsk',
  },
  {
    value: 'Australia/Brisbane',
    name: 'GMT+10:00 - Brisbane',
  },
  {
    value: 'Australia/Sydney',
    name: 'GMT+10:00 - Sydney',
  },
  {
    value: 'Australia/Canberra',
    name: 'GMT+10:00 - Canberra',
  },
  {
    value: 'Australia/Melbourne',
    name: 'GMT+10:00 - Melbourne',
  },
  {
    value: 'Australia/Hobart',
    name: 'GMT+10:00 - Hobart',
  },
  {
    value: 'Australia/Adelaide',
    name: 'GMT+09:30 - Adelaide',
  },
  {
    value: 'Australia/Darwin',
    name: 'GMT+09:30 - Darwin',
  },
  {
    value: 'Asia/Irkutsk',
    name: 'GMT+09:00 - Irkutsk, Ulaan Bataar',
  },
  {
    value: 'Asia/Tokyo',
    name: 'GMT+09:00 - Osaka, Sapporo, Tokyo',
  },
  {
    value: 'Asia/Seoul',
    name: 'GMT+09:00 - Seoul',
  },
  {
    value: 'Asia/Hong_Kong',
    name: 'GMT+08:00 - Beijing, Chongqing, Hong Kong, Urumqi',
  },
  {
    value: 'Asia/Krasnoyarsk',
    name: 'GMT+08:00 - Krasnoyarsk',
  },
  {
    value: 'Australia/Perth',
    name: 'GMT+08:00 - Perth',
  },
  {
    value: 'Asia/Taipei',
    name: 'GMT+08:00 - Taipei',
  },
  {
    value: 'Asia/Bangkok',
    name: 'GMT+07:00 - Bangkok, Hanoi, Jakarta',
  },
  {
    value: 'Asia/Novosibirsk',
    name: 'GMT+07:00 - Novosibirsk',
  },
  {
    value: 'Asia/Rangoon',
    name: 'GMT+06:30 - Yangon Rangoon',
  },
  {
    value: 'Asia/Dhaka',
    name: 'GMT+06:00 - Astana, Dhaka',
  },
  {
    value: 'Asia/Yekaterinburg',
    name: 'GMT+06:00 - Ekaterinburg',
  },
  {
    value: 'Asia/Katmandu',
    name: 'GMT+05:45 - Kathmandu',
  },
  {
    value: 'Asia/Kolkata',
    name: 'GMT+05:30 - Chennai, Kolkata, Mumbai, New Delhi',
  },
  {
    value: 'Asia/Tashkent',
    name: 'GMT+05:00 - Asia/Tashkent',
  },
  {
    value: 'Asia/Kabul',
    name: 'GMT+04:30 - Kabul',
  },
  {
    value: 'Asia/Dubai',
    name: 'GMT+04:00 - Abu Dhabi, Muscat',
  },
  {
    value: 'Asia/Baku',
    name: 'GMT+04:00 - Baku',
  },
  {
    value: 'Europe/Moscow',
    name: 'GMT+04:00 - Moscow, St. Petersburg, Volgograd',
  },
  {
    value: 'Asia/Tbilisi',
    name: 'GMT+04:00 - Tbilisi',
  },
  {
    value: 'Asia/Yerevan',
    name: 'GMT+04:00 - Yerevan',
  },
  {
    value: 'Asia/Tehran',
    name: 'GMT+03:30 - Tehran',
  },
  {
    value: 'Asia/Baghdad',
    name: 'GMT+03:00 - Baghdad',
  },
  {
    value: 'Europe/Minsk',
    name: 'GMT+03:00 - Kaliningrad, Minsk',
  },
  {
    value: 'Asia/Kuwait',
    name: 'GMT+03:00 - Kuwait, Riyadh',
  },
  {
    value: 'Africa/Addis_Ababa',
    name: 'GMT+03:00 - Nairobi',
  },
  {
    value: 'Asia/Amman',
    name: 'GMT+02:00 - Amman',
  },
  {
    value: 'Europe/Bucharest',
    name: 'GMT+02:00 - Athens, Bucharest',
  },
  {
    value: 'Asia/Beirut',
    name: 'GMT+02:00 - Beirut',
  },
  {
    value: 'Africa/Cairo',
    name: 'GMT+02:00 - Cairo',
  },
  {
    value: 'Asia/Damascus',
    name: 'GMT+02:00 - Damascus',
  },
  {
    value: 'Africa/Blantyre',
    name: 'GMT+02:00 - Harare, Pretoria',
  },
  {
    value: 'Europe/Helsinki',
    name: 'GMT+02:00 - Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
  },
  {
    value: 'Europe/Istanbul',
    name: 'GMT+02:00 - Istanbul',
  },
  {
    value: 'Asia/Jerusalem',
    name: 'GMT+02:00 - Jerusalem',
  },
  {
    value: 'Europe/Nicosia',
    name: 'GMT+02:00 - Nicosia',
  },
  {
    value: 'Europe/Amsterdam',
    name: 'GMT+01:00 - Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  },
  {
    value: 'Europe/Belgrade',
    name: 'GMT+01:00 - Belgrade, Bratislava, Budapest, Prague, Ljubljana',
  },
  {
    value: 'Europe/Brussels',
    name: 'GMT+01:00 - Brussels, Copenhagen, Madrid, Paris',
  },
  {
    value: 'Europe/Warsaw',
    name: 'GMT+01:00 - Sarajevo, Skopje, Warsaw, Zagreb',
  },
  {
    value: 'Africa/Algiers',
    name: 'GMT+01:00 - West Central Africa',
  },
  {
    value: 'Africa/Windhoek',
    name: 'GMT+01:00 - Windhoek',
  },
  {
    value: 'Africa/Casablanca',
    name: 'GMT-00:00 - Casablanca',
  },
  {
    value: 'UTC',
    name: 'GMT-00:00 - Coordinated Universal Time',
  },
  {
    value: 'Africa/Abidjan',
    name: 'GMT-00:00 - Monrovia, Reykjavik',
  },
  {
    value: 'Europe/London',
    name: 'GMT-00:00 - Dublin, Lisabon, London, Edinburgh',
  },
  {
    value: 'Atlantic/Cape_Verde',
    name: 'GMT-01:00 - Cape Verde Is.',
  },
  {
    value: 'Atlantic/Azores',
    name: 'GMT-01:00 - Azores',
  },
  {
    value: 'America/Noronha',
    name: 'GMT-02:00 - Mid-Atlantic',
  },
  {
    value: 'Etc/GMT+2',
    name: 'GMT-02:00 - Coordinated Universal Time - 02',
  },
  {
    value: 'America/Sao_Paulo',
    name: 'GMT-03:00 - Brasilia',
  },
  {
    value: 'America/Argentina/Buenos_Aires',
    name: 'GMT-03:00 - Buenos Aires',
  },
  {
    value: 'America/Cayenne',
    name: 'GMT-03:00 - Cayenne, Fortalenza',
  },
  {
    value: 'America/Godthab',
    name: 'GMT-03:00 - Greenland',
  },
  {
    value: 'America/Montevideo',
    name: 'GMT-03:00 - Montevideo',
  },
  {
    value: 'America/St_Johns',
    name: 'GMT-03:30 - Newfoundland',
  },
  {
    value: 'America/Asuncion',
    name: 'GMT-04:00 - Asuncion',
  },
  {
    value: 'America/Goose_Bay',
    name: 'GMT-04:00 - Atlantic Time, Goose Bay',
  },
  {
    value: 'America/Glace_Bay',
    name: 'GMT-04:00 - Atlantic Time, Canada',
  },
  {
    value: 'America/Cuiaba',
    name: 'GMT-04:00 - Cuiaba',
  },
  {
    value: 'America/La_Paz',
    name: 'GMT-04:00 - Georgetown, La Paz, Manaus, San Juan',
  },
  {
    value: 'America/Santiago',
    name: 'GMT-04:00 - Santiago',
  },
  {
    value: 'America/Caracas',
    name: 'GMT-04:30 - Caracas',
  },
  {
    value: 'America/Bogota',
    name: 'GMT-05:00 - Bogota, Lima, Quito, Rio Branco',
  },
  {
    value: 'America/New_York',
    name: 'GMT-05:00 - Eastern Time, US; Canada',
  },
  {
    value: 'America/Havana',
    name: 'GMT-05:00 - Cuba',
  },
  {
    value: 'America/Indiana/Indianapolis',
    name: 'GMT-05:00 - Indiana (East)',
  },
  {
    value: 'America/Belize',
    name: 'GMT-06:00 - Central America',
  },
  {
    value: 'America/Chicago',
    name: 'GMT-06:00 - Central Time, US; Canada',
  },
  {
    value: 'America/Cancun',
    name: 'GMT-06:00 - Guadalajara, Mexico City, Monterrey',
  },
  {
    value: 'Canada/Saskatchewan',
    name: 'GMT-06:00 - Saskatchewan',
  },
  {
    value: 'America/Dawson_Creek',
    name: 'GMT-07:00 - Arizona',
  },
  {
    value: 'America/Chihuahua',
    name: 'GMT-07:00 - Chihuahua, La Paz, Mazatlan',
  },
  {
    value: 'America/Denver',
    name: 'GMT-07:00 - Mountain Time, US; Canada',
  },
  {
    value: 'America/Ensenada',
    name: 'GMT-08:00 - Tijuana, Baja California',
  },
  {
    value: 'America/Los_Angeles',
    name: 'GMT-08:00 - Pacific Time, US; Canada',
  },
  {
    value: 'America/Anchorage',
    name: 'GMT-09:00 - Alaska',
  },
  {
    value: 'Etc/GMT+10',
    name: 'GMT-10:00 - Hawaii',
  },
  {
    value: 'Pacific/Midway',
    name: 'GMT-11:00 - Midway Island, Samoa',
  },
  {
    value: 'Etc/GMT+12',
    name: 'GMT-12:00 - International Date Line West',
  },
];

export const LanguageList = [
  {
    name: 'English',
    value: 'en',
  },
  {
    name: 'Arabic (عربي ʿarabī)',
    value: 'ar',
  },
  {
    name: 'Chinese (中文)',
    value: 'zh-CN',
  },
  {
    name: 'Dutch',
    value: 'nl',
  },
  {
    name: 'Filipino (Tagalog)',
    value: 'tl',
  },
  {
    name: 'French (Français)',
    value: 'fr',
  },
  {
    name: 'German (Deutsche)',
    value: 'de',
  },
  {
    name: 'Hindi (हिंदी)',
    value: 'hi',
  },

  {
    name: 'Indonesian (bahasa Indonesia)',
    value: 'id',
  },
  {
    name: 'Italian (Italiano)',
    value: 'it',
  },
  {
    name: 'Japanese (日本語)',
    value: 'ja',
  },
  {
    name: 'Persian (Farsi)',
    value: 'fa',
  },
  {
    name: 'Polish (Język Polski)',
    value: 'pl',
  },

  {
    name: 'Portuguese (Portuguese)',
    value: 'pt',
  },
  {
    name: 'Spanish (Español)',
    value: 'es',
  },
  {
    name: 'Swedish (Svenska)',
    value: 'sv',
  },
  {
    name: 'Thai (แบบไทย)',
    value: 'th',
  },
  {
    name: 'Turkish (Türkiye Türkçesi)',
    value: 'tr',
  },
  {
    name: 'Ukrainian (Українська)',
    value: 'uk',
  },
  {
    name: 'Vietnamese (Tiếng Việt)',
    value: 'vi',
  },
];

export const getMfaMethodPermission = (
  mfaMethod: string,
  mfaLimits: OhsMfaLimits,
  currentMfaConfig: string,
  mfaOptions: OhsMFAsettings | undefined
) => {
  const mfaMethodLimit = { ...mfaLimits } as any;
  const hasMethodAccess =
    (currentMfaConfig === 'required' && mfaMethodLimit[mfaMethod]) ||
    (mfaMethodLimit[mfaMethod] && currentMfaConfig === 'optional') ||
    (mfaMethodLimit[mfaMethod] && mfaOptions?.method === mfaMethod) ||
    (currentMfaConfig === 'required' && mfaOptions?.method === mfaMethod) ||
    (currentMfaConfig === 'disabled' && mfaOptions?.method === mfaMethod) ||
    (currentMfaConfig === 'optional' && mfaOptions?.method === mfaMethod)
      ? true
      : false;

  return hasMethodAccess;
};

export const getMfaSettingsPermission = (
  currentMfaConfig: string,
  mfaOptions: OhsMFAsettings | undefined,
  getMfaLimitsData: boolean
) => {
  const mfaHasAccess =
    (currentMfaConfig === 'required' && getMfaLimitsData) ||
    (currentMfaConfig === 'disabled' && mfaOptions?.enabled) ||
    (currentMfaConfig === 'optional' && getMfaLimitsData) ||
    (!getMfaLimitsData && mfaOptions?.enabled)
      ? true
      : false;

  return mfaHasAccess;
};
