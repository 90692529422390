import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { Button } from 'semantic-ui-react';
import _ from 'lodash';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import OhsTextField from '../../global/form/fields/OhsTextField';
import OhsModal from '../../global/modal/OhsModal';
import OhsDateField from '../../global/form/fields/OhsDateField';
import OhsRadioField from '../../global/form/fields/OhsRadioField';
import {
  OhsFormLayoutType,
  OhsFormSettings,
} from '../../global/form/OhsFormModels';
import { OhsQuickInspection } from '../OhsInspectionModels';
import {
  createNewOneOffChild,
  startInspection,
} from '../OhsInspectionServices';
import { getOhsUser } from '../../user/OhsUser';
import { getCurrentTimezone } from '../../common/OhsDataParse';

interface Props {
  isOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  closeAllModal?: () => void;
  recordData: any;
}

const OhsOneOffInspectionModal = (props: Props) => {
  const user = getOhsUser();
  const { isOpen, setModalOpen, recordData, closeAllModal } = props;
  const recordId = recordData._id;
  const tier = recordData?.tier.type || '';
  const navigate = useNavigate();
  const [ohsOhsInspection, setOhsInspection] = React.useState(
    new OhsQuickInspection()
  );
  const ohsFormSettings = new OhsFormSettings(
    ohsOhsInspection,
    OhsFormLayoutType.AngularModalForm
  );

  const isRecurring =
    !_.isNil(recordData.frequencyKey) && !_.isNil(recordData.frequencyValue);

  const [inspectionCommencedDate, dateDue]: [string, string] =
    ohsFormSettings.useFormMethods.watch([
      'inspectionCommencedDate',
      'dateDue',
    ]);

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleViewInspection = () => {
    navigate(`/inspection/${recordId}/details?tempType=tp`);
    navigate(0);
  };

  const formSubmit = async (type: string, formData: any) => {
    if (type === 'LATER' && _.isEmpty(formData?.notes.trim())) {
      ohsFormSettings.useFormMethods.setError('notes', {
        type: 'notEmpty',
        message: `To ensure visibility of who is expected to complete this inspection, instructions are required when Start Later has been selected.`,
      });
      return;
    }

    const newFormData = {
      dateDue: formData.dateDue,
      notes: formData.notes,
      attachments: [],
    };
    setOhsInspection(newFormData);

    const callback = (res: any) => {
      const inspectionId = res?._id;
      const taskId = res?.execute?.task?._id;
      if (closeAllModal) {
        closeAllModal();
      } else {
        handleClose();
      }
      if (type === 'NOW') {
        const startInspectionForm = {
          tz: getCurrentTimezone(),
          attachments: [],
        };
        startInspection(inspectionId, taskId, startInspectionForm);
        navigate(`/inspection/signoff/${inspectionId}/signoff/${taskId}`);
        navigate(0);
      }
    };
    createNewOneOffChild(recordId, newFormData, callback);
  };

  useEffect(() => {
    if (
      inspectionCommencedDate === 'today' &&
      dateDue !== moment().format('YYYY-MM-DD')
    ) {
      const newForm = {
        inspectionCommencedDate: inspectionCommencedDate,
        dateDue: moment().format('YYYY-MM-DD'),
      };
      ohsFormSettings.useFormMethods.reset(newForm);
    }
  }, [inspectionCommencedDate]);

  useEffect(() => {
    if (isOpen) {
      const newForm = {
        inspectionCommencedDate: 'today',
        dateDue: moment().format('YYYY-MM-DD'),
        notes: '',
      };
      ohsFormSettings.useFormMethods.reset(newForm);
    }
  }, [isOpen]);

  return (
    <OhsModal
      open={isOpen}
      title={`${isRecurring ? 'Recurring ' : ''} Inspection: ${
        recordData.title
      }`}
      onClose={handleClose}
      maxWidth={'md'}
      ContentNode={
        <>
          <Box minHeight="360px">
            <Grid container rowSpacing={4}>
              <Grid item xs={4}>
                <OhsRadioField
                  title="Due Date"
                  id="inspectionCommencedDate"
                  required={true}
                  options={[
                    { name: 'Today', value: 'today' },
                    { name: 'Other', value: 'other' },
                  ]}
                  ohsFormSettings={ohsFormSettings}
                  row
                />
              </Grid>
              <Grid item xs={8}>
                <OhsDateField
                  id="dateDue"
                  required={true}
                  ohsFormSettings={ohsFormSettings}
                  disabled={inspectionCommencedDate === 'today'}
                />
              </Grid>
              <Grid item xs={12}>
                <OhsTextField
                  type="textarea"
                  title="Instructions"
                  description="Consider including details regarding “by-who” or “where” the inspection is to be completed"
                  id="notes"
                  required={false}
                  rows={5}
                  ohsFormSettings={ohsFormSettings}
                />
              </Grid>
            </Grid>
          </Box>
        </>
      }
      actionNode={
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6}>
            <Button onClick={handleViewInspection} color="blue">
              View Inspection
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            container
            justifyContent="flex-end"
            spacing={2}
          >
            <Grid item>
              {tier !== 'core.tier.T5' && (
                <Button
                  onClick={ohsFormSettings.useFormMethods.handleSubmit(
                    (res: any) => formSubmit('LATER', res)
                  )}
                  color="blue"
                >
                  Start Later
                </Button>
              )}
            </Grid>
            <Grid item>
              {user?.tier.type !== 'core.tier.T5'}
              <Button
                onClick={ohsFormSettings.useFormMethods.handleSubmit(
                  (res: any) => formSubmit('NOW', res)
                )}
                color="green"
              >
                Start {tier === 'core.tier.T5' ? 'Inspection' : 'Now'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
};

export default OhsOneOffInspectionModal;
