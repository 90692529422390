import React from 'react';

import { FormControl, Grid, MenuItem, TextField } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { OhsFormFieldBaseProps } from '../OhsFormModels';
import _ from 'lodash';
import OhsFormFieldLayout from '../OhsFormFieldLayout';
import { ohsPhoneCountryCode } from '../../../visitor/sign/OhsPhoneCountryCode';
import { OhsPhoneNumber } from './OhsFormFieldModels';
import { PhoneNumberDivider } from 'common/OhsCusvalsUtils';
import { returnFieldStyle, returnVariant } from './OhsFieldLayout';

export const getPhoneNumber = (value: string): OhsPhoneNumber => {
  if (value && value.indexOf && value.indexOf(PhoneNumberDivider) > 0) {
    const phoneParts = value.split(PhoneNumberDivider);
    const getPhoneCode = phoneParts[0] === 'null' ? '' : phoneParts[0];
    const getPhoneNumber = phoneParts[1] === 'null' ? '' : phoneParts[1];
    if (phoneParts && phoneParts.length === 2)
      return { code: getPhoneCode, number: getPhoneNumber };
  }
  return {
    code: '',
    number: '',
  };
};

interface Props extends OhsFormFieldBaseProps {}
function InputEle(props: Props) {
  const disabled = props.disabled || false;
  const error = _.get(
    props.ohsFormSettings.useFormMethods.formState.errors,
    props.id
  );
  const phoneInfo = getPhoneNumber(
    props.ohsFormSettings.useFormMethods.watch(props.id)
  );

  const handleCodeChange = (event: SelectChangeEvent) => {
    const code = event.target.value as string;
    const newCurr = { ...phoneInfo };
    newCurr.code = code;
    const updateCode = newCurr.code + PhoneNumberDivider + newCurr.number;
    props.ohsFormSettings.useFormMethods.setValue(props.id, updateCode);
    props.ohsFormSettings.useFormMethods.trigger(props.id);
  };
  const handleNumberChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const number = event.target.value as string;
    const newCurr = { ...phoneInfo };
    newCurr.number = number;
    const updateNumber = newCurr.code + PhoneNumberDivider + newCurr.number;
    props.ohsFormSettings.useFormMethods.setValue(props.id, updateNumber);
    props.ohsFormSettings.useFormMethods.trigger(props.id);
  };

  React.useEffect(() => {
    props.ohsFormSettings.useFormMethods.register(props.id, {
      validate: {
        notEmpty: (value: any) => {
          if (props.required === true) {
            if (phoneInfo && phoneInfo.code && phoneInfo.number) {
              return true;
            }
          } else {
            return true;
          }

          return false;
        },
        phoneCodeNumComplete: (value: any) => {
          const currencyValue = getPhoneNumber(
            props.ohsFormSettings.useFormMethods.getValues(props.id)
          );
          const numberEmpty =
            currencyValue?.number == null || currencyValue?.number === '';
          const codeEmpty =
            currencyValue?.code == null || currencyValue?.code === '';
          if (numberEmpty && !codeEmpty) {
            return 'If country code is provided, Please enter a valid phone number.';
          }
          if (!numberEmpty && codeEmpty) {
            return 'If phone number value is provided, please select your country code.';
          }
        },
      },
    });
  }, [
    phoneInfo,
    props.id,
    props.ohsFormSettings.useFormMethods,
    props.ohsFormSettings.useFormMethods.register,
    props.required,
  ]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4} sx={{ paddingLeft: '24px !important' }}>
        <FormControl component="fieldset" fullWidth>
          <Select
            margin="dense"
            value={phoneInfo.code}
            error={error != null}
            onChange={handleCodeChange}
            required={props.required}
            variant={returnVariant(props.ohsFormSettings.layout)}
            size="small"
            disabled={disabled}
            style={returnFieldStyle(props.ohsFormSettings.layout, {
              marginTop: '8px',
            })}
          >
            <MenuItem value="" disabled={props.required}>
              <span style={{ color: 'gray' }}>
                --
                {props.required
                  ? ' Please select country code '
                  : ' Select none '}
                --
              </span>
            </MenuItem>
            {ohsPhoneCountryCode.map((item) => {
              return (
                <MenuItem key={item.countryCode + item.code} value={item.code}>
                  {item.title}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={8} sx={{ paddingLeft: '5px !important' }}>
        <FormControl component="fieldset" fullWidth>
          <TextField
            type="text"
            error={error != null}
            fullWidth
            margin="dense"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            value={phoneInfo.number}
            onChange={handleNumberChange}
            required={props.required}
            disabled={disabled || !phoneInfo.code}
            variant={returnVariant(props.ohsFormSettings.layout)}
            style={returnFieldStyle(props.ohsFormSettings.layout)}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default function OhsPhoneNumberField(props: Props) {
  return (
    <>
      <OhsFormFieldLayout {...props} inputEle={<InputEle {...props} />} />
    </>
  );
}
