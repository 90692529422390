import React, { useEffect } from 'react';
import { delCookie, getCookie, setCookie } from '../common/OhsDataParse';

const GoogleTranslate = () => {
  const getAppLang = getCookie('googtrans')
    ? getCookie('googtrans')
    : localStorage.getItem('scAppLang');

  const googleTranslateElementInit = () => {
    // @ts-ignore
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'en',
        includedLanguages:
          'en,ar,zh-CN,nl,tl,fr,de,hi,it,fa,pl,pt,es,sv,tr,vi,uk,th,id,ja',
        // @ts-ignore
        autoDisplay: false,
        // @ts-ignore
        layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT,
      },
      'google_translate_element'
    );
  };
  useEffect(() => {
    // @ts-ignore
    var addScript = document.createElement('script');
    addScript.setAttribute(
      'src',
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    );
    document.body.appendChild(addScript);
    // @ts-ignore
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  useEffect(() => {
    setTimeout(() => {
      // Google Translate updates the 'googtrans' cookie and make it expire AFTER translating the page.
      // reset the cookie again with expiry based on the current cookie
      if (getAppLang) {
        delCookie('googtrans');
        setCookie('googtrans', getAppLang, 365);
      }
    }, 2000);
  }, [getAppLang]);

  return (
    <>
      <div id="google_translate_element"></div>
    </>
  );
};

export default GoogleTranslate;
