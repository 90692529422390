import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { Button, Icon } from 'semantic-ui-react';
import { makeStyles } from 'tss-react/mui';
import { Grid } from '@mui/material';

import OhsCusvalFieldFactory from './OhsCusvalFieldFactory';
import { OhsCusval, OhsCusvalType } from './OhsCusvalModel';
import {
  setCusvalValue,
  setCusvalValueToForm,
  returnInvalidCusvals,
  returnMatchingCusvalByConfig,
} from './OhsCusvalServices';
import { OhsFormLayoutType, OhsFormSettings } from '../OhsFormModels';
import { RadioOptions } from '../fields/OhsRadioField';

interface Props {
  id: string;
  cusvalsConfig: OhsCusval[];
  ohsFormSettings: OhsFormSettings;
}

const useStyles = makeStyles()(() => ({
  actionButton: {
    marginRight: '0px !important',
    marginLeft: '4px !important',
  },
}));

export default function OhsCusvalFieldsSection(props: Props) {
  const { classes } = useStyles();
  const [cusvalHolder, setCusvalHolder] = useState({});
  const [invalidCusvals, setInvalidCusval] = useState<OhsCusval[]>([]);
  const [invalidIsInitialized, setInvalidIsInitialized] =
    useState<boolean>(false);
  const [isFormatted, setIsFormatted] = useState<boolean>(false);
  const [cusForm, setCusForm] = useState();
  const [allValues, setAllValues] = useState<any>();
  const ohsFormSettings = new OhsFormSettings(
    cusForm,
    props.ohsFormSettings.layout
  );
  const propValues = props.ohsFormSettings.useFormMethods.getValues();
  const customPropValues = propValues[props.id];
  const formValues = ohsFormSettings.useFormMethods.watch();

  const memoizedInvalidCusval = useMemo(() => invalidCusvals, [invalidCusvals]);
  const memoizedCusvalsConfig = useMemo(
    () => props.cusvalsConfig,
    [props.cusvalsConfig]
  );
  const memoizedCusvalHolder = useMemo(() => cusvalHolder, [cusvalHolder]);

  // const deleteInvalidCusval = (item: OhsCusval) => {
  //   const newInvalidCusvalList = invalidCusvals.filter(
  //     (cusval) => cusval._id !== item._id
  //   );
  //   setInvalidCusval(newInvalidCusvalList);

  //   let newCusValue = formValues[props.id];
  //   newCusValue = _.omit(newCusValue, props.id);
  //   setCusForm(newCusValue);
  //   ohsFormSettings.useFormMethods.setValue(props.id, newCusValue);

  //   const newPropValues = customPropValues.filter(
  //     (cusval: OhsCusval) => cusval._id !== item._id
  //   );
  //   props.ohsFormSettings.useFormMethods.setValue(props.id, newPropValues);
  // };

  // Delayed setValue to prevent lag
  const registerInvalidCusvalChanges = useCallback(
    _.debounce((invalidList: OhsCusval[], allVal: any, propValues: any) => {
      let newCusValue = allVal[props.id];
      let newPropValues: any = [];
      if (Array.isArray(propValues)) {
        newPropValues = [...propValues];
      }

      invalidList.map((item: OhsCusval) => {
        if (_.isNil(_.get(newCusValue, item?._id, null))) {
          newCusValue = {
            ...newCusValue,
            [item._id]: item.value,
          };
        }
        const target = newPropValues.find(
          (prop: any) => prop?._id === item?._id
        );
        if (_.isUndefined(target)) {
          newPropValues = [...newPropValues, item];
        }
      });
      if (!_.isEqual(newCusValue, allVal[props.id])) {
        setCusForm(newCusValue);
        ohsFormSettings.useFormMethods.setValue(props.id, newCusValue);
        props.ohsFormSettings.useFormMethods.setValue(props.id, newPropValues);
      }
    }, 1000),
    [props.id, props.ohsFormSettings.useFormMethods]
  );

  useEffect(() => {
    registerInvalidCusvalChanges.cancel();
    registerInvalidCusvalChanges(invalidCusvals, formValues, customPropValues);
  }, [memoizedInvalidCusval, formValues, customPropValues]);

  // Delayed setValue to prevent lag
  const registerChanges = useCallback(
    _.debounce((currCusvalHolder?: any, allVal?: any) => {
      const allCusval = allVal[props.id];
      if (
        !_.isEqual(
          JSON.stringify(currCusvalHolder),
          JSON.stringify(allCusval)
        ) &&
        !_.isNil(allCusval)
      ) {
        const newParsedValue = setCusvalValue(
          allCusval,
          props.cusvalsConfig,
          customPropValues
        );
        props.ohsFormSettings.useFormMethods.setValue(props.id, newParsedValue);
        let newHolder = { ...allCusval };
        setCusvalHolder(newHolder);
      }
    }, 1000),
    [props.id, props.cusvalsConfig, props.ohsFormSettings.useFormMethods]
  );

  useEffect(() => {
    registerChanges.cancel();
    registerChanges(memoizedCusvalHolder, formValues);
  }, [JSON.stringify(formValues), JSON.stringify(memoizedCusvalHolder)]);

  useEffect(() => {
    async function formatForm() {
      if (!isFormatted && !_.isNil(props.id)) {
        if (!_.isEmpty(customPropValues)) {
          if (
            typeof customPropValues === 'object' &&
            !_.isEqual(customPropValues, cusForm)
          ) {
            const newCusValue = await setCusvalValueToForm(customPropValues);
            setCusForm(newCusValue);
            ohsFormSettings.useFormMethods.setValue(props.id, newCusValue);
            setIsFormatted(true);
          }
        }
      }
    }
    formatForm();
  }, [isFormatted, customPropValues]);

  // Set invalid values
  useEffect(() => {
    // if configs exist but no values, automatically set as no invalid
    if (memoizedCusvalsConfig.length > 0 && customPropValues.length === 0) {
      setInvalidIsInitialized(true);
    } else if (!invalidIsInitialized) {
      const newInvalidCusvals = returnInvalidCusvals(
        customPropValues,
        props.cusvalsConfig
      );

      let newInvalidList: OhsCusval[] = newInvalidCusvals;

      invalidCusvals.map((item) => {
        const existingInNewValue = newInvalidCusvals.find(
          (newInvalid) => newInvalid._id === item._id
        );
        if (!existingInNewValue) {
          newInvalidList = [...newInvalidList, item];
        }
      });

      if (props.cusvalsConfig.length > 0 || customPropValues > 0) {
        if (!_.isEqual(newInvalidList, invalidCusvals)) {
          setInvalidCusval(newInvalidList);
        }
        setInvalidIsInitialized(true);
      }
    }
  }, [customPropValues, memoizedCusvalsConfig]);

  const formSubmit = () => {};

  props.ohsFormSettings.useFormMethods.register(props.id, {
    validate: {
      noErrors: async () => {
        const withError = await ohsFormSettings.useFormMethods
          .handleSubmit(formSubmit)()
          .then(() => {
            const error = _.get(
              ohsFormSettings.useFormMethods.formState.errors,
              props.id
            );
            if (!_.isUndefined(error) && !_.isEmpty(error) && !_.isNil(error)) {
              return true;
            } else {
              return false;
            }
          });
        return !withError;
      },
    },
  });

  const returnCusval = (
    item: OhsCusval,
    index: number,
    isInvalid?: boolean
  ) => {
    let additionalProps = {};
    if (isInvalid) {
      if (item.type === 'currency') {
        const options: string[] = [item.value?.code || ''];
        additionalProps = { ...additionalProps, options };
      }
      if (
        item.type === 'radio' ||
        item.type === 'checkbox' ||
        item.type === 'select_single' ||
        item.type === 'select_multiple'
      ) {
        let options: RadioOptions = [];
        (item.value || []).map((val: string) => {
          options = [...options, { name: val, value: val }];
        });
        additionalProps = { ...additionalProps, options };
      }
    }

    return (
      <Grid
        key={index}
        item
        xs={12}
        sm={
          ohsFormSettings.layout === OhsFormLayoutType.AngularModalForm &&
          (item.type === OhsCusvalType.Text ||
            item.type === OhsCusvalType.Color ||
            item.type === OhsCusvalType.Date ||
            item.type === OhsCusvalType.Time ||
            item.type === OhsCusvalType.Url ||
            item.type === OhsCusvalType.Email ||
            item.type === OhsCusvalType.Number)
            ? 4
            : false
        }
      >
        <OhsCusvalFieldFactory
          key={index}
          sectionId={props.id}
          cusval={{
            ...item,
            ...additionalProps,
          }}
          ohsFormSettings={ohsFormSettings}
        />
      </Grid>
    );
  };

  useEffect(() => {
    const newAllValues = {
      ...allValues,
      ...(formValues[props.id] || {}),
    };
    setAllValues(newAllValues);
    const newFormValues = returnMatchingCusvalByConfig(
      memoizedCusvalsConfig,
      newAllValues,
      invalidCusvals
    );
    ohsFormSettings.useFormMethods.setValue(props.id, newFormValues);
  }, [memoizedCusvalsConfig]);

  return (
    <Grid
      container
      spacing={
        ohsFormSettings.layout === OhsFormLayoutType.AngularModalForm ||
        ohsFormSettings.layout === OhsFormLayoutType.AngularModalUnderlinedForm
          ? 3
          : 0
      }
    >
      {(invalidCusvals || []).map((item, index) => {
        const cusvalItem = {
          ...item,
          disabled: true,
          required: false,
          // renderAction: (
          //   <Button
          //     icon={true}
          //     onClick={() => deleteInvalidCusval(item)}
          //     className={classes.actionButton}
          //     color={'red'}
          //   >
          //     <Icon name="trash" />
          //   </Button>
          // ),
        };
        return returnCusval(cusvalItem, index, true);
      })}
      {(props.cusvalsConfig || []).map((item, index) =>
        returnCusval(item, index)
      )}
    </Grid>
  );
}
