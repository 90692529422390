import React, { useEffect } from 'react';
import _ from 'lodash';
import { TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { OhsFormFieldBaseProps, OhsFormLayoutType } from '../OhsFormModels';
import './OhsFormField.css';
import OhsFormFieldLayout from '../OhsFormFieldLayout';
import OhsDatePicker, {
  useStyles as datePickerUseStyle,
} from '../../picker/OhsDatePicker';
import { dateDiffBetween, isValidDate } from '../../utils/OhsDateServices';
import { returnVariant } from './OhsFieldLayout';

interface Props extends OhsFormFieldBaseProps {
  maxDate?: { name: string; date: string };
  minDate?: { name: string; date: string };
  isClearable?: boolean;
}

function InputEle(props: Props) {
  const { classes } = datePickerUseStyle();
  const disabled = props.disabled || false;
  const isClearable =
    props.isClearable !== undefined ? props.isClearable : true;
  const date = props.ohsFormSettings.useFormMethods.watch(props.id) ?? null;

  const handleClear = () => {
    props.ohsFormSettings.useFormMethods.setValue(
      props.id,
      props.defaultValue !== undefined ? props.defaultValue : null
    );
    props.ohsFormSettings.useFormMethods.trigger(props.id);
  };

  React.useEffect(() => {
    props.ohsFormSettings.useFormMethods.register(props.id, {
      required: props.required === true,
      validate: {
        isValidDate: (value: any) => {
          if (value && !isValidDate(value)) {
            return 'Please enter a valid date!';
          }
          return true;
        },
        maxDate: (value: any) => {
          if (value && props.maxDate) {
            try {
              if (dateDiffBetween(value, props.maxDate.date) < 0) {
                return `The date must not be after ${props.maxDate.name}.`;
              }
            } catch (error) {
              console.error(error);
            }
          }

          return true;
        },
        minDate: (value: any) => {
          if (value && props.minDate) {
            try {
              if (dateDiffBetween(props.minDate.date, value) < 0) {
                return `The date must not be before ${props.minDate.name}.`;
              }
            } catch (error) {
              console.error(error);
            }
          }

          return true;
        },
        notEmpty: (value: any) => {
          if (props.required === true) {
            if (value == null || value.trim() === '') {
              return false;
            }
          }

          return true;
        },
      },
    });
  }, [
    date,
    props.maxDate,
    props.id,
    props.ohsFormSettings.useFormMethods,
    props.required,
    props.minDate,
  ]);

  useEffect(() => {
    if (!_.isNil(date) && _.isEmpty(date)) {
      handleClear();
    }
  }, [date]);

  return (
    <div
      style={{
        marginTop:
          props.ohsFormSettings.layout === OhsFormLayoutType.AngularModalForm
            ? '8px'
            : undefined,
      }}
    >
      <OhsDatePicker
        maxDate={props.maxDate?.date}
        minDate={props.minDate?.date}
        disabled={disabled}
        onChange={(val) => {
          props.ohsFormSettings.useFormMethods.setValue(props.id, val || null);
          props.ohsFormSettings.useFormMethods.trigger(props.id);
        }}
        date={date}
        renderInput={(params: any) => (
          <div className={classes.container}>
            <TextField
              fullWidth
              variant={returnVariant(props.ohsFormSettings.layout)}
              size="small"
              {...params}
            />
            {!disabled && isClearable && date && (
              <div className={classes.closeContainer}>
                <CloseIcon className={classes.close} onClick={handleClear} />
              </div>
            )}
          </div>
        )}
      />
    </div>
  );
}

export default function OhsDateField(props: Props) {
  return (
    <>
      <OhsFormFieldLayout {...props} inputEle={<InputEle {...props} />} />
    </>
  );
}
