import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Button } from 'semantic-ui-react';
import OhsModalFormSection from '../../global/form/OhsModalFormSection';
import OhsModuleBackground from '../../global/register/OhsModuleBackground';
import OhsModuleHeader from '../../global/register/OhsModuleHeader';
import { editFormLayout } from '../../global/theme/OhsPresetStyles';
import OhsTextField from '../../global/form/fields/OhsTextField';
import {
  OhsFormLayoutType,
  OhsFormSettings,
} from '../../global/form/OhsFormModels';

import OhsSelectField from '../../global/form/fields/OhsSelectField';
import {
  delCookie,
  getAngularLocalStorageConfig,
  getCookie,
  setCookie,
} from '../../common/OhsDataParse';
import OhsPhoneNumberField, {
  getPhoneNumber,
} from '../../global/form/fields/OhsPhoneNumberField';
import { PhoneNumberDivider } from 'common/OhsCusvalsUtils';
import OhsEmailField from '../../global/form/fields/OhsEmailField';
import {
  formatTime,
  LanguageList,
  userTimeZoneList,
} from './OhsUserSettingsUtils';
import OhsSwitchField from '../../global/form/fields/OhsSwitchField';
import moment from 'moment';
import OhsUserSettingsMFA from './mfa/OhsUserSettingsMFA';
import { getModuleConfigValue } from '../UserServices';
import { ModuleType } from '../../models/OhsObject';
import { toast } from 'react-toastify';
import { Box } from '@mui/material';
import { OhsUserSettings } from './OhsUserSettingsModels';
import {
  updateUserDetails,
  getUserSettings,
  fetchOrgLimitDetails,
} from './OhsUserSettingsServices';
import { getOhsUser } from '../OhsUser';
import { useNavigate } from 'react-router-dom';

const OhsUserSettingsForm = () => {
  const user = getOhsUser();
  const navigate = useNavigate();
  const formLayout = editFormLayout();
  const layout = formLayout.classes;
  const [userLimits, setUserLimits] = React.useState<any>();
  const [pushOrgEnable, setPushOrgEnable] = React.useState<boolean>(true);
  const [pushInstantOrgEnable, setPushInstantOrgEnable] =
    React.useState<boolean>(false);
  const [pushScheduledOrgEnable, setPushScheduledOrgEnable] =
    React.useState<boolean>(false);
  const [pushMessage, setPushMessage] = React.useState<string>('');
  const userTimeZone = getAngularLocalStorageConfig('l10nConfig', 'TIMEZONE');
  const [userProfile, setOhsUserProfileRecord] = useState(
    new OhsUserSettings()
  );

  const ohsFormSettings = new OhsFormSettings(
    new OhsUserSettings(),
    OhsFormLayoutType.AngularPageForm
  );

  const [language, newPassword]: [string, string] =
    ohsFormSettings.useFormMethods.watch(['language', 'newPassword']);

  const formSubmit = async (assetForm: any) => {
    const currentLang = getCookie('googtrans').replace('/en/', '');
    const getUserPhone = assetForm.userphone;
    const userFormData: any = {
      ...assetForm,
      language: currentLang ? currentLang : 'en',
      password: assetForm.newPassword,
      notifyTime: {
        hours: moment(assetForm.notifyTime, 'HH:mm').hours(),
        minutes: moment(assetForm.notifyTime, 'HH:mm').minutes(),
      },
      phone: getPhoneNumber(getUserPhone).number,
      phoneCountryCode: getPhoneNumber(getUserPhone).code,
    };

    const updateUserReq = await updateUserDetails(userFormData);
    if (updateUserReq) {
      if (assetForm.newPassword) {
        setTimeout(() => {
          toast.success('Your password has been changed successfully !');
          ohsFormSettings.useFormMethods.reset({
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
          });
        }, 2000);
      }
      console.log('User Updated !');
    }
  };

  const fetchUserInfo = async () => {
    const currentLang = getCookie('googtrans').replace('/en/', '');
    const getUserData = await getUserSettings();
    const getNotifyTime = formatTime(
      getModuleConfigValue(
        getUserData.configuration,
        ModuleType.Notification,
        'NOTIFY_TIME'
      )
    );
    const getNotifySubscribeEmail = getModuleConfigValue(
      getUserData.configuration,
      ModuleType.Notification,
      'NOTIFY_SUBSCRIBE_EMAIL'
    );
    const getNotifySubscribePush = getModuleConfigValue(
      getUserData.configuration,
      ModuleType.Notification,
      'NOTIFY_SUBSCRIBE_PUSH'
    );
    const getBookmarkEnable = getModuleConfigValue(
      getUserData.configuration,
      ModuleType.Bookmark,
      'ENABLE_FOR_USER'
    );
    const genUserFormData = {
      ...getUserData,
      language: currentLang ? currentLang : 'en',
      timezone: userTimeZone,
      notifyTime: getNotifyTime,
      notifySubscribeEmail: getNotifySubscribeEmail ?? true,
      notifySubscribePush: getNotifySubscribePush ?? true,
      bookmarkEnable: getBookmarkEnable,
      userphone:
        getUserData.phoneCountryCode + PhoneNumberDivider + getUserData.phone,
    };
    ohsFormSettings.useFormMethods.reset(genUserFormData);
    if (getUserData) setOhsUserProfileRecord(genUserFormData);
  };

  useEffect(() => {
    fetchUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // set language cookie
    document.documentElement.setAttribute('lang', language);
    const currentLang = getCookie('googtrans');
    const newLang = '/en/' + language;

    if (language && newLang !== currentLang) {
      delCookie('googtrans');
      setCookie('googtrans', '/en/' + language, 365);
      localStorage.setItem('scAppLang', '/en/' + language);
      navigate(0);
    }
  }, [language]);

  React.useEffect(() => {
    const getOrgLimits = async () => {
      const orgLimits = await fetchOrgLimitDetails({ ...user?.tier });
      setUserLimits(orgLimits);
    };
    if (user && user?.tierNum > 2) getOrgLimits();
  }, []);

  React.useEffect(() => {
    let pushOrgEnable = false;
    if (userLimits && userLimits?.counts.push != null) {
      pushOrgEnable = true;
      let pushInstantOrgEnable = userLimits.counts.push.instant;
      let pushScheduledOrgEnable = userLimits.counts.push.scheduled;
      let pushMessage = '';

      if (pushInstantOrgEnable !== true && pushScheduledOrgEnable !== true) {
        pushMessage = 'Push Notifications are disabled at Organisation';
      } else if (
        pushInstantOrgEnable === true &&
        pushScheduledOrgEnable !== true
      ) {
        pushMessage =
          'Scheduled Push Notifications are disabled at Organisation';
      } else if (
        pushInstantOrgEnable !== true &&
        pushScheduledOrgEnable === true
      ) {
        pushMessage = 'Instant Push Notifications are disabled at Organisation';
      }

      setPushMessage(pushMessage);
      setPushOrgEnable(pushOrgEnable);
      setPushInstantOrgEnable(pushInstantOrgEnable);
      setPushScheduledOrgEnable(pushScheduledOrgEnable);
    }
  }, [userLimits]);

  return (
    <>
      <OhsModuleBackground>
        <main className={layout.layout}>
          <OhsModuleHeader title={`User Settings`} textAlign="center" />
          <OhsModalFormSection title={'Details'}>
            <OhsTextField
              type="text"
              title="Name"
              id="name"
              required={true}
              ohsFormSettings={ohsFormSettings}
            />
            <OhsEmailField
              title="Email"
              id="email"
              required={false}
              ohsFormSettings={ohsFormSettings}
              disabled={true}
            />
            <OhsPhoneNumberField
              title="Mobile numbers"
              id="userphone"
              description={''}
              required={false}
              ohsFormSettings={ohsFormSettings}
            />
            <OhsSelectField
              title="Time Zone"
              id="timezone"
              required={true}
              options={userTimeZoneList}
              ohsFormSettings={ohsFormSettings}
            />
            <OhsTextField
              type="time"
              title="Notify Time"
              id="notifyTime"
              required={true}
              ohsFormSettings={ohsFormSettings}
            />
            {user && user?.tierNum !== 2 && (
              <OhsSwitchField
                title="Email Notifications"
                id="notifySubscribeEmail"
                required={false}
                ohsFormSettings={ohsFormSettings}
              />
            )}
            <OhsSelectField
              title="Language"
              id="language"
              required={true}
              options={LanguageList}
              ohsFormSettings={ohsFormSettings}
            />
            {user && user?.tierNum > 2 && pushOrgEnable === true && (
              <OhsSwitchField
                title="Push Notifications"
                id="notifySubscribePush"
                required={false}
                disabled={
                  pushInstantOrgEnable !== true &&
                  pushScheduledOrgEnable !== true
                }
                description={pushMessage}
                ohsFormSettings={ohsFormSettings}
              />
            )}
            <OhsSwitchField
              title="Flag"
              id="bookmarkEnable"
              required={false}
              ohsFormSettings={ohsFormSettings}
            />
          </OhsModalFormSection>
          {user && user.isSso === false && (
            <OhsModalFormSection title={'Update Password'}>
              <OhsTextField
                type="password"
                title="Current Password"
                id="oldPassword"
                required={false}
                ohsFormSettings={ohsFormSettings}
              />
              <OhsTextField
                type="password"
                title="New Password"
                id="newPassword"
                required={false}
                ohsFormSettings={ohsFormSettings}
                passwordValidation={true}
              />
              <OhsTextField
                type="password"
                title="Confirm New Password"
                id="confirmNewPassword"
                required={false}
                comparePasswords={newPassword}
                ohsFormSettings={ohsFormSettings}
                passwordValidation={true}
              />
            </OhsModalFormSection>
          )}
          <Box sx={{ padding: '0', display: 'flex', justifyContent: 'end' }}>
            <Button
              onClick={() =>
                ohsFormSettings.useFormMethods.reset(new OhsUserSettings())
              }
              color="grey"
            >
              Reset
            </Button>
            <Button
              onClick={ohsFormSettings.useFormMethods.handleSubmit(formSubmit)}
              color="green"
            >
              Submit
            </Button>
          </Box>
          {user && user.isSso === false && (
            <OhsUserSettingsMFA
              title={'Enable Multi-Factor Authentication'}
              email={userProfile.email}
              phoneCountryCode={userProfile.phoneCountryCode}
              phoneNum={userProfile.phone}
              modalUse={false}
            />
          )}
        </main>
      </OhsModuleBackground>
    </>
  );
};

export default OhsUserSettingsForm;
